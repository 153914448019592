import { Link } from 'gatsby'
import videojs from 'video.js'
import React from 'react'
import SiteHelmet from '../components/SiteHelmet'
import SiteNav from '../components/SiteNav'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import VideoPlayer from '../components/VideoPlayer'
import moment from 'moment-timezone'

class LiveStreamBase extends React.Component {
  constructor({ data, ...props }) {
    super(props)
    this.data = data
    this.state = {}
  }

  render() {
    const events = this.data.allMarkdownRemark.edges.filter(e => e.node.frontmatter.stream)
    const futureEvents = events.filter(e => moment().isBefore(e.node.frontmatter.startTime))
    const currentEvents = events.filter(e => moment().isBefore(e.node.frontmatter.endTime) && moment().isAfter(e.node.frontmatter.startTime))
    const current = currentEvents[0] && currentEvents[0].node
    const future = futureEvents[0] && futureEvents[0].node
    const handleOptionChange = (changeEvent) => {
      this.setState({
        chat: changeEvent.target.value
      });
    }


    return (
      <Layout full>
        <SiteHelmet title="MB productions - live streaming (HQ)" description="We do live streaming from time to time. Tune in here for some quality tunes and check out our DJs at work!">
        </SiteHelmet>
        <SiteNav sticky />

        <HeaderGeneric title="live streaming DJs">
          <p>We're also streaming to <a href={`https://twitch.tv/${this.props.twitchAccount}`}>twitch.tv/{this.props.twitchAccount}</a></p>
        </HeaderGeneric>

        <main id="main" className={`stream ${this.state.chat || 'chat-side'}`}>
          <section id="video" className="stream-column stream-column__stream">
            <VideoPlayer vid={this.props.vid} current={current} future={future}></VideoPlayer>
            <div className={`${videojs.browser.IS_SAFARI ? "stream-column__chat--hide-phone" : ""}`}>
              <form className="chat-options chat-options-full">
                <span className="icon fa-comments-o"></span>
                <span>
                  <input type="radio" id="chat-side" name="chat" value="chat-side" checked={(this.state.chat || 'chat-side') === 'chat-side'} onChange={handleOptionChange} />
                  <label for="chat-side">Side</label>
                </span>
                <span>
                  <input type="radio" id="chat-under" name="chat" value="chat-under" checked={this.state.chat === 'chat-under'} onChange={handleOptionChange} />
                  <label for="chat-under">Under</label>
                </span>
                <span>
                  <input type="radio" id="chat-off" name="chat" value="chat-off" checked={this.state.chat === 'chat-off'} onChange={handleOptionChange} />
                  <label for="chat-off">Off</label>
                </span>
              </form>
              <form className="chat-options chat-options-limited">
                <span className="icon fa-comments-o"></span>
                <span>
                  <input type="radio" id="chat-limited-under" name="chat-limited" value="chat-under" checked={this.state.chat !== 'chat-off'} onChange={handleOptionChange} />
                  <label for="chat-limited-under">Under</label>
                </span>
                <span>
                  <input type="radio" id="chat-limited-off" name="chat-limited" value="chat-off" checked={this.state.chat === 'chat-off'} onChange={handleOptionChange} />
                  <label for="chat-limited-off">Off</label>
                </span>
              </form>
            </div>
          </section>
          {this.state.chat !== 'chat-off' &&
            <section id="chat" className={`stream-column stream-column__chat ${videojs.browser.IS_SAFARI ? "stream-column__chat--hide-phone" : ""}`}>
              <iframe title="chat" frameBorder="0"
                scrolling="no"
                id="chat_embed"
                src={`https://www.twitch.tv/embed/${this.props.twitchAccount}/chat?parent=www.mixbprod.com`}
                className="chat"
                style={{ "border": "1px solid #eee" }}
                width="100%">
              </iframe>
            </section>
          }
          <section id="content">
            <div className="stream-column__chat--show-phone">
              <h2>
                Mobile users:
              </h2>
              <p>
                To be able to chat while watching the stream, we recommend:
                <ol>
                  <li>
                    install the <a href="https://apps.apple.com/us/app/twitch-live-game-streaming/id460177396">twitch app for iOS</a>  or <a href="https://play.google.com/store/apps/details?id=tv.twitch.android.app">twitch app for Android</a>
                  </li>
                  <li>
                    make an account if you need to
                  </li>
                  <li>
                    then visit <a href={`https://twitch.tv/${this.props.twitchAccount}`}>twitch.tv/{this.props.twitchAccount}</a>
                  </li>
                </ol>
              </p>
            </div>
            <ul className="actions">
              <li>
                <Link className="button" to="/live-stream-vj">get some visual enjoyment <span className="icon fa-podcast"></span></Link>
              </li>
              <li>
                <Link className="button" to="/">back to home page</Link>
              </li>
            </ul>
          </section>
        </main>
        <section>
        </section>
      </Layout>
    )
  }


}

export default LiveStreamBase
